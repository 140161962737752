import React from "react";
import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Checkbox,
  Drawer,
  Box,
  Avatar,
  Button,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Skeleton
} from "@mui/material";

import Search from "@mui/icons-material/Search";
import Check from "@mui/icons-material/Check";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ConstructionIcon from "@mui/icons-material/Construction";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CloseIcon from "@mui/icons-material/Close";

import WayfasterLogoW from "../wayfaster-logo-w.svg";
import WayfasterLogo from "../wayfaster-logo.svg";

import CandidateProfile from "../components/candidate/CandidateProfile";
import FormField from "../components/common/FormField";
import FloatingToolbar from "../components/common/FloatingToolbar";
import ChipTag from "../components/common/ChipTag";

import api from '../api/api'

import { Sidebar } from "../components/nav/Sidebar";

function CandidatesPreVetted() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [teamId, setTeamId] = useState('');
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const [showBookDemo, setShowBookDemo] = useState(false);
  const [demoName, setDemoName] = useState("");
  const [demoEmail, setDemoEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [currentTeam, setCurrentTeam] = useState({});
  const [activeCandidate, setActiveCandidate] = useState({});
  const [activeCandidateIndex, setActiveCandidateIndex] = useState(-1);
  const [headerTags, setHeaderTags] = useState([
    'Brand Designers', 'Native English', 'PHP Developer', 'From USA', 'Remote'
  ]);
  const [selected, setSelected] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const [search, setSearch] = useState("");
  const [activeSidebar, setActiveSidebar] = useState(false);

  const [toolbarShowing, setToolbarShowing] = useState(false);
  const [fetchCandidatesCalled, setFetchCandidatesCalled] = useState(false);
  const [fetchCurrentTeamCalled, setFetchCurrentTeamCalled] = useState(false);

  const navigate = useNavigate();

  const candidates_filter = useMemo(() => {
    return candidates
            .filter((candidate) => {
              if (search.trim().length == 0) return true;
              else
                return (
                  (candidate.first_name + " " + candidate.last_name)
                    .toLowerCase()
                    .includes(search.toLowerCase()) || JSON.stringify(candidate).toLowerCase().includes(search.toLowerCase())
                );
            })
  }, [search, candidates])

  const focusedCandidates = useMemo(() => {
    return candidates.filter((candidate) => selected.includes(candidate._id))
  }, [selected, candidates])

  const teamRequestedCandidateIds = useMemo(() => {
    if (currentTeam && Object.keys(currentTeam).length > 0) {
      return currentTeam.candidates_requested
    } else {
      return []
    }
  }, [currentTeam])

  useEffect(() => {
    setFetchCandidatesCalled(true);
    api.getCandidates()
      .then((response) => {
        console.log('candidates', response.data.filter((candidate) => candidate.session && candidate.session.scorecard && candidate.session.scorecard.length > 0))
        // setCandidates(response.data)
        // currently set filter to only include candidates that have done an AI interview
        setCandidates(response.data.filter((candidate) => candidate.session && candidate.session.scorecard && candidate.session.scorecard.length > 0))
        setFetchCandidatesCalled(false);
      })
      .catch((err) => {
        console.error(err)
        setFetchCandidatesCalled(false);
      })

    // get team for team requested list
    if (user && fetchCurrentTeamCalled == false) {
      const teamId = localStorage.getItem("teamId");
      if (teamId && Object.keys(currentTeam).length === 0) {
        api.getTeam({ userId: user.email, teamId: teamId }).then((res) => {
          console.log("got it", res.data);
          setCurrentTeam(res.data);
          setTeamId(teamId)
          setFetchCurrentTeamCalled(true);
        })
      }
    }
  }, [user, currentTeam, fetchCurrentTeamCalled])

  const tagClicked = (tag) => {
    console.log(tag)
  }

  // Request Hire on toolbar
  const requestHire = (mode) => {
    console.log('requesting hire', mode)
    console.log(selected)
    api.requestHire({
      candidateIds: mode === 'selected' ? selected : [activeCandidate._id],
      userId: user.email,
      teamId: teamId
    })
    .then((response) => {
      console.log('hire request', response)
      setFetchCurrentTeamCalled(false);
    })
    .catch((err) => {
      console.error(err)
      setFetchCurrentTeamCalled(false);
    })
  }

  // Review Candidates on toolbar
  const reviewCandidates = () => {
    setActiveCandidate(focusedCandidates[0]);
    setActiveCandidateIndex(0);
    setActiveSidebar(true);
  }
  
  const navigateCandidatePrevious = () => {
    let candidates_to_navigate = selected.length > 0 ? focusedCandidates : candidates;
    setActiveCandidate(candidates_to_navigate[activeCandidateIndex - 1])
    setActiveCandidateIndex((prev) => prev - 1)
  };

  const navigateCandidateNext = () => {
    let candidates_to_navigate = selected.length > 0 ? focusedCandidates : candidates;
    setActiveCandidate(candidates_to_navigate[activeCandidateIndex + 1])
    setActiveCandidateIndex((prev) => prev + 1)
  };

  const handleSelect = (candidate_id) => {
    console.log("handle", candidate_id);
    let selected_candidates = selected;
    let idx = selected_candidates.findIndex(
      (candidate) => candidate == candidate_id
    );
    if (idx == -1) {
      setSelected([...selected, candidate_id]);
    } else {
      selected_candidates.splice(idx, 1);
      setSelected([...selected_candidates]);
    }
  };

  const getOverallScore = (scorecard) => {
    if (!scorecard || scorecard.length == 0) return -1;
    const scores = scorecard.map((signal) => signal.score);
    const average = array => array.reduce((a, b) => a + b) / array.length;
    return average(scores).toFixed(1);
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  const getIcon = (icon) => {
    switch (icon) {
      case "retail":
        return <PointOfSaleIcon style={{ fontSize: "20px" }}></PointOfSaleIcon>;
      case "medical":
        return (
          <MonitorHeartIcon style={{ fontSize: "20px" }}></MonitorHeartIcon>
        );
      case "design":
        return (
          <DesignServicesIcon style={{ fontSize: "20px" }}></DesignServicesIcon>
        );
      case "phone":
        return <LocalPhoneIcon style={{ fontSize: "20px" }}></LocalPhoneIcon>;
      case "construction":
        return (
          <ConstructionIcon style={{ fontSize: "20px" }}></ConstructionIcon>
        );
      case "customer":
        return <PersonIcon style={{ fontSize: "20px" }}></PersonIcon>;
      case "data":
        return <BarChartIcon style={{ fontSize: "20px" }}></BarChartIcon>;
      case "bolt":
      default:
        return (
          <ElectricBoltIcon style={{ fontSize: "20px" }}></ElectricBoltIcon>
        );
    }
  };

  const normalizeScore = (score, scale) => {
    if (Number(score) == 0) return 1;
    return (Number(score) * 100) / scale;
  };

  const getColorFromScore = (score, scale) => {
    if (score === -1) return "#DADADA";
    const range = normalizeScore(score, scale);
    if (range < 50) return "#E02E2E";
    if (range < 75) return "#E27C1E";
    else return "#5C9B0A";
  };

  return (
    <>
    {isLoading ? <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/> :
    <div className="d-flex flex-row">
      {/* Nav Sidebar */}
      {/* {isAuthenticated && <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}></Sidebar>} */}

      {/* Main Content */}
      <div
        className="d-flex flex-col"
        style={{ width: "100%", background: "white" }}
      >
        {/* Upper Header */}
        {!isAuthenticated && <>
          <div className="d-flex flex-row" style={{ padding: '12px 32px' }}>
            <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />

            <Button
              className="btn-main my-auto"
              style={{ marginLeft: "auto", height: "36px" }}
              onClick={() => {
                setShowBookDemo(true)
              }}
            >
              <span className="font-circular">Book a Demo</span>
            </Button>
          </div>
          
          {/* Landing Header */}
          <div className="d-flex flex-col" style={{ padding: '56px 32px', backgroundColor: 'var(--color-dark-mode)' }}>
            <h1 className="m-0 font-circular" style={{ color: "#fff", fontSize: '32px' }}>
              Wayfaster hiring of <span style={{ color: 'var(--color-primary)' }}>pre-selected</span> candidates
            </h1>
            <div className="d-flex flex-row" style={{ marginTop: '24px' }}>
              {/* make into tags clickable */}
              {headerTags.map((tag) => <Button
                className="btn-main my-auto"
                style={{ marginRight: "12px", padding: '12px', backgroundColor: 'var(--color-hover-dark) !important' }}
                onClick={() => tagClicked(tag)}
              >
                {tag}
              </Button>)}
              
            </div>
          </div>

          
          {/* Book Demo Dialog */}
          <Dialog open={showBookDemo} onClose={() => setShowBookDemo(false)}>
            <DialogTitle>Book a Demo</DialogTitle>
            <DialogContent>
              <p style={{ fontSize: '14px', margin: 0 }}>See how quickly you can hire top talent - we'll reach out to you shortly.</p>
              <Grid container spacing={2} style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                  <FormField
                    label="Name"
                    value={demoName}
                    onChange={(e) => {
                      setDemoName(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label="Email"
                    value={demoEmail}
                    onChange={(e) => {
                      setDemoEmail(e.target.value)  
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button className="btn-main gray outlined" onClick={() => setShowBookDemo(false)}>
                Cancel
              </Button>
              <Button 
                className="btn-main"
                disabled={demoName.length === 0 || demoEmail.length === 0}
                onClick={() => {
                  // Save candidate details
                  api.bookDemo({ name: demoName, email: demoEmail })
                  .then(response => {
                    setOpenSnackbar(true);
                    setSnackbarMsg('Sent a request for a demo! We\'ll get back to you shortly.');
                    setShowBookDemo(false);
                  })
                  .catch(error => {
                    console.error('Failed to update candidate details:', error);
                  });
                }}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </>}

        {/* Candidates Header / Filter */}
        <div className="d-flex flex-col" style={{ textAlign: "left", padding: "24px 32px" }}>
          <div
            className="d-flex flex-col"
            style={{ textAlign: "left", marginBottom: '12px' }}
          >
            <h5 className="m-0">
              Filter Candidates by
            </h5>
          </div>
          <div
            className="d-flex flex-row m-0"
          >
            {/* Search */}
            <div className="d-flex flex-row" style={{ flex: 1 }}>
              <FormField
                className="d-flex"
                style={{
                  maxWidth: "296px",
                  marginRight: "auto",
                  marginBottom: 0,
                }}
                label=""
                height="36px"
                icon={
                  <Search
                    className="color-gray"
                    style={{ fontSize: "20px" }}
                  ></Search>
                }
                placeholder="Search candidates"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* Candidate Grid */}
        <div
          className="d-flex flex-col"
          style={{ textAlign: "left", padding: "0px 32px" }}
        >
          <h5 className="m-0">
            Candidates
          </h5>
        </div>
        <div
          className="wrap-grid"
          style={{ width: "100%", overflowY: "auto", padding: "12px 32px" }}
        >
          { fetchCandidatesCalled ? <>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
              <Skeleton variant="rectangular" className="d-flex flex-col card" width="100%" height={236}/>
            </>
            :
            candidates_filter
            .map((candidate, index) => {
              return (
                <Box
                  className={`d-flex flex-col card clickable ${
                    selected.includes(candidate._id) ? "selected" : ""
                  }`}
                  style={{
                    padding: "24px",
                    background: "var(--color-offwhite-bg)",
                  }}
                  onClick={() => {
                    // handleSelect(candidate.candidate_id)
                    setActiveCandidate(candidate);
                    setActiveCandidateIndex(index);
                    setActiveSidebar(true);
                  }}
                >
                  {/* Header and Score */}
                  <div className="d-flex flex-row">
                    <Avatar
                      sx={{
                        bgcolor: "var(--color-main)",
                        color: "white",
                        width: 32,
                        height: 32,
                        borderRadius: "50%",
                        fontSize: "18px",
                        marginBottom: "24px",
                      }}
                    >
                      {candidate.picture ? <img
                        src={candidate.picture}
                        width={40}
                        length={40}
                      /> : <>
                        {candidate.first_name && candidate.first_name.charAt(0)}
                        {candidate.last_name && candidate.last_name.charAt(0)}
                      </>}
                    </Avatar>
                    <div
                      className="d-flex flex-col"
                      style={{ marginLeft: "12px" }}
                    >
                      <h3
                        className="section-heading"
                        style={{ fontSize: "16px" }}
                      >
                        {candidate.first_name} {candidate.last_name}
                        <span style={{ fontWeight: "400" }}>
                          {candidate.location}
                        </span>
                      </h3>
                      <p
                        className="d-flex color-subheading m-0"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        <WorkOutlineIcon
                          className="color-gray"
                          style={{ fontSize: "16px", marginRight: "8px" }}
                        ></WorkOutlineIcon>
                        {candidate.best_suited_roles && candidate.best_suited_roles[0]}
                      </p>
                      <p
                        className="d-flex color-subheading m-0"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        <PaidOutlinedIcon
                          className="color-gray"
                          style={{ fontSize: "16px", marginRight: "8px" }}
                        ></PaidOutlinedIcon>
                        {candidate.rate} {candidate.currency?.currency}
                      </p>
                    </div>

                    {candidate.session && <div
                      className="d-flex mb-auto"
                      style={{
                        backgroundColor: getColorFromScore(
                          getOverallScore(candidate.session.scorecard),
                          DEFAULT_SCALE
                        ),
                        height: "35px",
                        width: "35px",
                        borderRadius: "8px",
                        marginLeft: "auto",
                      }}
                    >
                      <h2
                        className="m-auto"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        {getOverallScore(candidate.session.scorecard) === -1 ? 'N/A' : getOverallScore(candidate.session.scorecard)}
                      </h2>
                    </div>}
                  </div>

                  {/* Scorecard Items */}
                  <div
                    className="d-flex flex-row"
                    style={{ marginTop: "16px" }}
                  >
                    {candidate.session && candidate.session.scorecard && candidate.session.scorecard.map(
                      (scorecardItem, index) => {
                        if (index < 2)
                          return (
                            <div
                              className="d-flex flex-col"
                              style={{
                                marginRight: index < 2 ? "16px" : "",
                                flex: 1,
                              }}
                            >
                              <h5
                                className="m-0"
                                style={{
                                  fontSize: "13px",
                                  marginRight: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                {scorecardItem.signal}
                              </h5>
                              <div className="d-flex flex-row">
                                <LinearProgress
                                  className="my-auto"
                                  variant="determinate"
                                  value={normalizeScore(
                                    scorecardItem.score,
                                    scorecardItem.scale
                                      ? scorecardItem.scale
                                      : DEFAULT_SCALE
                                  )}
                                  style={{
                                    backgroundColor: "#DADADA",
                                    height: "4px",
                                    borderRadius: "4px",
                                    flex: 6,
                                  }}
                                  sx={{
                                    [`& .MuiLinearProgress-bar`]: {
                                      backgroundColor: getColorFromScore(
                                        scorecardItem.score,
                                        scorecardItem.scale
                                          ? scorecardItem.scale
                                          : DEFAULT_SCALE
                                      ),
                                    },
                                  }}
                                />
                                <h5
                                  className="m-0"
                                  style={{
                                    marginLeft: "16px",
                                    color: getColorFromScore(
                                      scorecardItem.score,
                                      scorecardItem.scale
                                        ? scorecardItem.scale
                                        : DEFAULT_SCALE
                                    ),
                                  }}
                                >
                                  {scorecardItem.score}/
                                  {scorecardItem.scale
                                    ? scorecardItem.scale
                                    : DEFAULT_SCALE}
                                </h5>
                              </div>
                            </div>
                          );
                      }
                    )}
                  </div>

                  <p
                    className="color-gray m-0"
                    style={{ marginTop: "12px", fontSize: "12px" }}
                  >
                    See all skills in profile details
                  </p>

                  {/* Tags */}
                  <div
                    className="d-flex flex-row"
                    style={{ marginTop: "auto", paddingTop: "12px" }}
                  >
                    <Button
                      className="btn-main gray outlined"
                      style={{ marginRight: "12px" }}
                    >
                      {isAuthenticated ? 'Review candidate' : 'View Profile'}
                    </Button>
                    {teamRequestedCandidateIds && teamRequestedCandidateIds.includes(candidate._id) && <p className="d-flex my-auto" style={{ fontSize: '14px', color: 'var(--color-main)' }}>
                       <Check style={{ fontSize: '18px', marginRight: '4px', color: 'var(--color-accept)' }}></Check>
                      <span style={{ color: 'var(--color-accept)' }}>Requested Hire</span>
                    </p>}

                    {isAuthenticated && <Checkbox
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSelect(candidate._id);
                      
                        if (selected.length > 0) setToolbarShowing(true)
                          else setToolbarShowing(false)
                        }
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: 'var(--color-main)',
                        },
                      }}
                      style={{ marginLeft: "auto", paddingRight: 0, height: '32px' }}
                      checked={selected.includes(candidate._id)}
                    />}
                  </div>
                </Box>
              );
            })}
        </div>

        {/* Info Sidebar */}
        {activeSidebar && <div style={{ height: '100vh', width: '100vw', opacity: 0.7, background: 'black', zIndex: 5000, position: 'fixed' }}></div>}
        <div
          className={`d-flex flex-col sidebar-right ${
            activeSidebar ? "sidebar-right-show" : ""
          }`}
          style={{ padding: "12px", zIndex: 5005 }}
        >
          {/* Nav Candidate Header */}
          <div
            className="d-flex flex-row"
            style={{ marginRight: "auto", height: "24px" }}
          >
            {!(selected.length === 1 && !selected.includes(activeCandidate._id)) && <>
            <button
              className={`btn-main small d-flex gray outlined ${
                activeCandidateIndex <= 0 ? "inactive" : ""
              }`}
              style={{ marginRight: '12px' }}
              disabled={activeCandidateIndex <= 0}
              onClick={() => {
                navigateCandidatePrevious();
              }}
            >
              <NavigateBefore
                fontSize="14px"
                className="color-heading m-auto"
              />
            </button>
            <button
              className={`btn-main small d-flex gray outlined ${
                activeCandidateIndex >= (selected.length > 0 ? focusedCandidates.length : candidates.length) - 1 ? "inactive" : ""
              }`}
              disabled={activeCandidateIndex >= (selected.length > 0 ? focusedCandidates.length : candidates.length) - 1}
              onClick={() => {
                navigateCandidateNext();
              }}
            >
              <NavigateNext fontSize="14px" className="color-heading m-auto" />
            </button>
            
            {/* Candidate indicator */}
            <h5
                className="color-gray"
                style={{ margin: "auto 8px", lineHeight: "20px" }}
              >
                Candidate <span className="color-main">{activeCandidateIndex + 1}</span> of <span className="color-main">{selected.length > 0 ? focusedCandidates.length : candidates.length}</span>
              </h5>
              </>
              }
          </div>
          <CloseIcon
            className="color-gray clickable"
            style={{
              marginLeft: "auto",
              fontSize: "24px",
              position: "absolute",
              top: "12px",
              right: "12px",
            }}
            onClick={() => {
              setActiveSidebar(false);
            }}
          />
          <CandidateProfile candidate={activeCandidate} compact={true} requestHire={() => requestHire('active')} requestedHire={teamRequestedCandidateIds && teamRequestedCandidateIds.includes(activeCandidate._id)}></CandidateProfile>
        </div>

        {/* Floating Toolbar */}
        <FloatingToolbar showing={toolbarShowing} setShowing={setToolbarShowing} selected={selected} reviewCandidates={reviewCandidates} requestHire={() => requestHire('selected')}></FloatingToolbar>
        </div>
      </div>}
      
      
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
    </>
  );
}

export default CandidatesPreVetted;
