var env = String(process.env.REACT_APP_ENV)
const proxyEndpoint = env === 'development' ? 'http://localhost:5555' : env === 'staging' ? 'https://staging-proxy-6f8d86796ff2.herokuapp.com' : 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
const wsEndpoint = env === 'development' ? 'ws://localhost:5555' : env === 'staging' ? 'wss://staging-proxy-6f8d86796ff2.herokuapp.com' : 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'

const SECTIONS_OPTIONS = ['Introduction', 'Background']

const SessionStatus = {
  NOT_STARTED: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  IN_PROGRESS: 4
}

const SessionDecision = {
  UNDECIDED: -1,
  FAIL: 0,
  PASS: 1
}

const SessionStatusString = {
  [SessionStatus.NOT_STARTED]: 'Not Started',
  [SessionStatus.INCOMPLETE]: 'Incomplete',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.IN_PROGRESS]: 'In Progress'
}

const SessionDecisionString = {
  [SessionDecision.UNDECIDED]: 'Undecided',
  [SessionDecision.FAIL]: 'Rejected',
  [SessionDecision.PASS]: 'Accepted'
}

const supportedLanguages = {
  "English": {
    language_name: "English",
    language_flag: "https://flagcdn.com/us.svg",
    language_code: "en"
  },
  "Chinese": {
    language_name: "Chinese",
    language_flag: "https://flagcdn.com/cn.svg",
    language_code: "zh"
  },
  "French": {
    language_name: "French",
    language_flag: "https://flagcdn.com/fr.svg",
    language_code: "fr"
  },
  "German": {
    language_name: "German",
    language_flag: "https://flagcdn.com/de.svg",
    language_code: "de"
  },
  "Hindi": {
    language_name: "Hindi",
    language_flag: "https://flagcdn.com/in.svg",
    language_code: "hi"
  },
  "Spanish": {
    language_name: "Spanish",
    language_flag: "https://flagcdn.com/es.svg",
    language_code: "es"
  }
}

const DEFAULT_LANGUAGE = "English"

const Language = {
  ENGLISH: "English",
  CHINESE: "Chinese",
  FRENCH: "French",
  GERMAN: "German",
  HINDI: "Hindi",
  SPANISH: "Spanish"
}

const PAGE_SIZE = 50;
const strToSessionStatus = {
  "in-progress": SessionStatus.IN_PROGRESS,
  "incomplete": SessionStatus.INCOMPLETE,
  "not-started": SessionStatus.NOT_STARTED,
  "completed": SessionStatus.COMPLETED,
  "accepted": SessionStatus.COMPLETED,
  "reject": SessionStatus.COMPLETED
}

export { proxyEndpoint, wsEndpoint, SECTIONS_OPTIONS, SessionStatus, SessionStatusString, supportedLanguages, DEFAULT_LANGUAGE, Language, SessionDecision, SessionDecisionString, PAGE_SIZE, strToSessionStatus }
