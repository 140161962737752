import React, { useState, useEffect } from 'react';
import { Sidebar } from '../nav/Sidebar';
import { Box } from '@mui/material';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    DndContext,
    useSensors,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    closestCenter
  } from "@dnd-kit/core";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, CircularProgress, Button, Menu, MenuItem } from '@mui/material';
import Add from '@mui/icons-material/Add';
import TrendBox from './TrendBox';
import SessionCounts from './SessionCounts';
import TooltipCustom from '../common/TooltipCustom';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart
} from 'chart.js';
import api from '../../api/api';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Splash from '../common/Splash';
import analyticsplash from '../../assets/analyticsplash.jpg';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const teamId = localStorage.getItem("teamId");
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );
    const [isFetchingInitial, setIsFetchingInitial] = useState(true);   
    const [selectedPeriod, setSelectedPeriod] = useState("1Y");
    const [selectedInterviewConfigId, setSelectedInterviewConfigId] = useState(null);
    const [trendBoxes, setTrendBoxes] = useState([]);
    const [minutesSavedChart, setMinutesSavedChart] = useState({});
    const [dollarSavedChart, setDollarSavedChart] = useState({});
    const [sessionStatusCounts, setSessionStatusCounts] = useState({});
    const [sessionDecisionCounts, setSessionDecisionCounts] = useState({});
    const [chartInstance, setChartInstance] = useState(null);
    const [interviewConfigs, setInterviewConfigs] = useState([]);
    const [interviewSelectorAnchorEl, setInterviewSelectorAnchorEl] = useState(null);
    const [initSessionsCount, setInitSessionsCount] = useState(null);

    const getCSSVariableValue = (variable) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    };

    useEffect(() => {
        const fetchInitSessionsCount = async () => {
            try {
                const response = await api.countSessions({ teamId });
                setInitSessionsCount(response.data.count);
            } catch (error) {
                console.error('Error fetching initial sessions count:', error);
                setInitSessionsCount(0);
            }
        };

        fetchInitSessionsCount();
    }, [teamId]);

    useEffect(() => {
        const fetchInterviewConfigs = async () => {
            try {
                if (!user) {
                    console.log('User not loaded yet');
                    return;
                }

                var userId = user.email;
                const overrideUserLocal = localStorage.getItem("globalOverrideUser");
                if (overrideUserLocal != null) {
                    userId = overrideUserLocal;
                }
                
                const response = await api.getInterviews(userId, teamId);
                setInterviewConfigs(response.map(config => ({
                    id: config._id,
                    title: config.title
                })));
            } catch (error) {
                console.error('Error fetching interview configs:', error);
                setInterviewConfigs([]);
            }
        };

        if (user) {
            fetchInterviewConfigs();
        }
    }, [user, teamId]);

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                setTrendBoxes([]);
                const response = await api.getAnalytics({ teamId, period: selectedPeriod, interviewConfigId: selectedInterviewConfigId });
                setTrendBoxes(response.data.trends);
                setMinutesSavedChart(response.data.minutes_saved_chart);
                setDollarSavedChart(response.data.dollar_saved_chart);
                setSessionStatusCounts(response.data.session_status_counts);
                setSessionDecisionCounts(response.data.session_decision_counts);
                setIsFetchingInitial(false);
            } catch (error) {
                console.error('Error fetching analytics:', error);
                setIsFetchingInitial(false);
            }
        };

        fetchAnalytics();
    }, [teamId, selectedPeriod, selectedInterviewConfigId]);

    useEffect(() => {
        // Cleanup previous instance
        if (chartInstance) {
            chartInstance.destroy();
        }

        const ctx = document.getElementById('myChart');
        if (ctx) {
            const newChartInstance = new Chart(ctx, {
                // your chart configuration
            });
            setChartInstance(newChartInstance);
        }

        // Cleanup on unmount
        return () => {
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, [selectedPeriod]);

    const getSelectedInterviewTitle = () => {
        const selected = interviewConfigs.find(config => config.id === selectedInterviewConfigId);
        return selected ? selected.title : "All";
    };

    if (isLoading) {
        return (
            <CircularProgress
            sx={{ color: "var(--color-primary)", margin: "auto" }}
            />
        );
    }

    {/* Default screen if team has no interviews yet */}
    if (initSessionsCount === 0) {
        return (
            <Splash
                img={analyticsplash}
                title="Start gathering insights!"
                paragraph="You don’t have any interviews yet. Create your first interview to start collecting valuable feedback and drive smarter decisions!"
            />
        )
    }

    const pillStyle = {
        padding: "8px 16px",
        borderBottom: "1px solid var(--color-divider)",
        borderRight: "1px solid var(--color-divider)",
        borderTop: "1px solid var(--color-divider)",
        cursor: "pointer",
        background: "white",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontFamily: "PPNeueMontreal, sans-serif",
        fontWeight: "bold",
        fontSize: "14px",
        color: "black",
        cursor: 'pointer',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    }

    return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
        >
          {/* <div className="w-50">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isAuthenticated && (
                <Sidebar
                  user={user}
                  isAuthenticated={isAuthenticated}
                  isLoading={isLoading}
                ></Sidebar>
              )}
            </Box>
        </div> */}

        {/* Loading Skeleton */}
        {isFetchingInitial && (
            <Box className="d-flex flex-col" style={{ padding: "24px 32px 16px 32px", width: "100%", marginLeft: "auto" }}>
                <Box className="d-flex flex-col">
                    <Skeleton variant="text" width={100} height={30} />
                    <Skeleton variant="text" width={250} height={18} />
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: '24px', gap: '12px' }}>
                    <Skeleton variant="text" width={350} height={60} />
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: '24px', gap: '24px' }}>
                    <Skeleton variant="rectangular" width="60%" height={300} style={{ borderRadius: '12px' }}/>
                    <Skeleton variant="rectangular" width="40%" height={300} style={{ borderRadius: '12px' }}/>
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: '24px', gap: '24px' }}>
                    <Skeleton variant="rectangular" width="33%" height={100} style={{ borderRadius: '12px' }}/>
                    <Skeleton variant="rectangular" width="33%" height={100} style={{ borderRadius: '12px' }}/>
                    <Skeleton variant="rectangular" width="33%" height={100} style={{ borderRadius: '12px' }}/>
                </Box>
            </Box>
        )}

        {initSessionsCount && !isFetchingInitial && (
            <Box
                    style={{
                    background: "#FAFAFA",
                    width: "100%",
                    }}
                >

                {/* Analytics Header */}
                <Box
                    style={{
                    background: "white",
                    width: "100%",
                    margin: "auto",
                    borderBottom: "1px solid lightgray",
                    }}
                >
                    <Box
                    style={{
                        width: "100%",
                        padding: "24px 32px 16px 32px",
                    }}
                    >
                        <div className="d-flex flex-row" style={{ width: "100%" }}>
                            <Box className="d-flex">
                                <Box className="d-flex flex-col">
                                <Box className="d-flex flex-row">
                                    <h3
                                    className="my-auto"
                                    style={{ marginRight: "8px" }}
                                    >
                                    Hey, {user.given_name ? user.given_name : user.name}
                                    </h3>
                                </Box>
                                    <h5
                                        className="color-gray my-auto"
                                        style={{ fontWeight: 500, marginTop: "8px" }}
                                    >
                                        Track and analyze the performance of the interviews you send out
                                    </h5>
                                </Box>
                            </Box>

                            {/* Add interview button */}
                            <div
                                className="d-flex flex-row"
                                style={{ marginLeft: "auto" }}
                            >
                                <button 
                                    className="btn-main d-flex"
                                onClick={() => {
                                    navigate('/create-interview')
                                }}
                                >
                                    <Add
                                    fontSize="14px"
                                    className="my-auto"
                                    sx={{ marginRight: "2px" }}
                                    ></Add>{" "}
                                    Add Interview
                                </button>
                            </div>
                        </div>
                    </Box>
                </Box>

                {/* Controls */}
                <Box className="d-flex flex-row" style={{ margin: "auto", width: "100%" }}>
                    <Box className="d-flex flex-row" style={{ width: "100%", padding: "24px 32px 16px 32px", gap: "12px" }}>

                        {/* Interview Selector */}
                        <Box className="d-flex flex-row">
                            <Button
                                className="d-flex"
                                style={{
                                    padding: "6px 12px",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    backgroundColor: "#fff",
                                    border: "1px solid var(--color-midgray)",
                                    borderRadius: "8px",
                                    color: "var(--color-main)",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    transition: "box-shadow 0.2s ease-in-out",
                                }}
                                onClick={(event) => setInterviewSelectorAnchorEl(event.currentTarget)}
                            >
                                {getSelectedInterviewTitle()}
                                <KeyboardArrowDown sx={{ fontSize: "20px", marginLeft: "4px" }} />
                            </Button>

                            <Menu
                                anchorEl={interviewSelectorAnchorEl}
                                open={Boolean(interviewSelectorAnchorEl)}
                                onClose={() => setInterviewSelectorAnchorEl(null)}
                                sx={{
                                    marginTop: "4px",
                                    "& .MuiPaper-root": {
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.1) !important",
                                        border: "1px solid var(--color-midgray)",
                                    },
                                    "& .MuiMenuItem-root": {
                                        fontSize: "14px",
                                        fontFamily: "PPNeueMontreal, sans-serif",
                                        fontWeight: "bold",
                                        padding: "8px 16px",
                                    }
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setSelectedInterviewConfigId(null);
                                        setInterviewSelectorAnchorEl(null);
                                    }}
                                >
                                    All
                                </MenuItem>
                                {interviewConfigs.map(config => (
                                    <MenuItem
                                        key={config.id}
                                        onClick={() => {
                                            setSelectedInterviewConfigId(config.id);
                                            setInterviewSelectorAnchorEl(null);
                                        }}
                                    >
                                        {config.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {/* Period Selector */}  
                        <Box className="d-flex flex-row">
                            <Box 
                                className="d-flex" 
                                onClick={() => setSelectedPeriod('1W')}
                                style={{ 
                                    ...pillStyle,
                                    background: selectedPeriod === '1W' ? 'var(--color-lightgray)' : 'white',
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                    borderLeft: "1px solid var(--color-divider)" 
                                }}
                            >
                                <div>1W</div>
                            </Box>
                            <Box 
                                className="d-flex"
                                onClick={() => setSelectedPeriod('1M')}
                                style={{
                                    ...pillStyle,
                                    background: selectedPeriod === '1M' ? 'var(--color-lightgray)' : 'white'
                                }}
                            >
                                <span>1M</span>
                            </Box>
                            <Box 
                                className="d-flex"
                                onClick={() => setSelectedPeriod('1Y')}
                                style={{
                                    ...pillStyle,
                                    background: selectedPeriod === '1Y' ? 'var(--color-lightgray)' : 'white'
                                }}
                            >
                                <span>1Y</span>
                            </Box>
                            <Box 
                                className="d-flex"
                                onClick={() => setSelectedPeriod('YTD')}
                                style={{
                                    ...pillStyle,
                                    background: selectedPeriod === 'YTD' ? 'var(--color-lightgray)' : 'white'
                                }}
                            >
                                <span>YTD</span>
                            </Box>
                            <Box 
                                className="d-flex"
                                onClick={() => setSelectedPeriod('ALL')}
                                style={{ 
                                    ...pillStyle,
                                    background: selectedPeriod === 'ALL' ? 'var(--color-lightgray)' : 'white',
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                    borderRight: "1px solid var(--color-divider)" 
                                }}
                            >
                                <span>ALL</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Main charts */}
                <Box className="d-flex flex-row" style={{ width: "100%", padding: "8px 32px 16px 32px", gap: "24px" }}>
                    <Box style={{ width: "60%", height: "300px", background: "linear-gradient(to top right, white, var(--color-primary-bg))", borderRadius: "12px", padding: "24px" }}>
                        <p
                            style={{
                                fontSize: '24px',
                                fontWeight: 700,
                                fontFamily: 'PPNeueMontreal, sans-serif',
                                color: 'black',
                                padding: '0px',
                                marginTop: "0px",
                                marginBottom: "8px"
                            }}
                        >{minutesSavedChart.total.toLocaleString()} minutes
                        </p>
                        <Box
                            style={{
                                fontSize: '14px',
                                color: 'var(--color-gray)',
                                fontFamily: 'PPNeueMontreal, sans-serif',
                                fontWeight: 'bold',
                                marginBottom: "16px"
                            }}
                        >
                            That's how many minutes you saved this past year
                        </Box>
                        <Box style={{ height: "200px", width: "100%", paddingBottom: "16px" }}>
                            <Bar
                                data={{
                                    labels: minutesSavedChart.labels,
                                    datasets: [{
                                        data: minutesSavedChart.data,
                                        backgroundColor: function(context) {
                                            const chart = context.chart;
                                            const {ctx, chartArea} = chart;
                                            if (!chartArea) {
                                                return null;
                                            }
                                            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                                            gradient.addColorStop(0, getCSSVariableValue('--color-primary-bg'));
                                            gradient.addColorStop(1, getCSSVariableValue('--color-gray'));
                                            return gradient;
                                        },
                                        borderWidth: 0,
                                        borderRadius: 8,
                                        barPercentage: 0.5
                                    }]
                                }}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            ticks: {
                                                color: getCSSVariableValue('--color-gray')
                                            },
                                            grid: {
                                                display: true,
                                                color: getCSSVariableValue('--color-divider'),
                                                drawBorder: false
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                color: getCSSVariableValue('--color-gray')
                                            },
                                            grid: {
                                                display: false
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                        
                    </Box>

                    <Box style={{ width: "40%", backgroundColor: "white", borderRadius: "16px", padding: "24px", height: "300px" }}>
                        <Box
                            style={{
                                fontSize: '14px',
                                color: 'var(--color-gray)',
                                fontFamily: 'PPNeueMontreal, sans-serif',
                                fontWeight: 'bold',
                                marginBottom: "8px",
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            And that's how many dollars you saved this past year
                            {dollarSavedChart.rate && (
                                <TooltipCustom title={`Assumes hourly wage of $${dollarSavedChart.rate.toFixed(2)}/hr`}>
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="var(--color-gray)" strokeWidth="2"/>
                                        <path d="M12 16V12M12 8H12.01" stroke="var(--color-gray)" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </TooltipCustom>
                            )}
                        </Box>
                        <Box style={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            marginBottom: "16px"
                        }}>
                            <p
                                style={{
                                    fontSize: '24px',
                                    fontWeight: 700,
                                    fontFamily: 'PPNeueMontreal, sans-serif',
                                    color: 'black',
                                    padding: '0px',
                                    margin: '0px',
                                }}
                            >${dollarSavedChart.total.toLocaleString()}
                            </p>
                            {/* <TrendPill percentage={dollarSavedChart.percentage} /> */}
                        </Box>
                        <Box style={{ height: "200px", width: "100%", paddingBottom: "16px" }}>
                            <Line
                                data={{
                                    labels: dollarSavedChart.labels,
                                    datasets: [{
                                        data: dollarSavedChart.data,
                                        backgroundColor: getCSSVariableValue('--color-primary'),
                                        borderColor: getCSSVariableValue('--color-primary'),
                                        borderWidth: 2,
                                        tension: 0, // Remove curve by setting tension to 0
                                        pointHoverRadius: 6, // Increase point size on hover
                                        pointBackgroundColor: getCSSVariableValue('--color-primary'),
                                        pointBorderColor: getCSSVariableValue('--color-primary')
                                    }]
                                }}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                    },
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            ticks: {
                                                color: getCSSVariableValue('--color-gray')
                                            },
                                            grid: {
                                                display: true,
                                                color: getCSSVariableValue('--color-divider'),
                                                drawBorder: false
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                color: '#808080'
                                            },
                                            grid: {
                                                display: false
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Trend Boxes */}
                <Box className="wrap-grid" style={{ width: "100%", padding: "8px 32px 16px 32px", gap: "24px" }}>
                    {trendBoxes && trendBoxes.length > 0 ? (
                        trendBoxes.map((box, index) => (
                            <Box key={index} style={{ flex: "1 1 calc(33.33% - 16px)", minWidth: "calc(33.33% - 16px)" }}>
                                <TrendBox 
                                    title={box.title}
                                    number={box.number}
                                    percentage={box.percentage}
                                    unit={box.unit} 
                                    period={selectedPeriod}
                                />
                            </Box>
                        ))
                    ) : (
                        <>
                            {[...Array(6)].map((_, index) => (
                                <Box key={index} style={{ flex: "1 1 calc(33.33% - 16px)", minWidth: "calc(33.33% - 16px)" }}>
                                    <Skeleton 
                                        variant="rectangular" 
                                        height={100} 
                                        sx={{ 
                                            borderRadius: '16px',
                                            backgroundColor: index === 5 ? 'transparent' : undefined 
                                        }} 
                                    />
                                </Box>
                            ))}
                        </>
                    )}
                </Box>

                {/* Session Counts */}
                {trendBoxes && trendBoxes.length > 0 ? (
                    <>
                        {sessionStatusCounts.data && sessionStatusCounts.data.reduce((a, b) => a + b, 0) > 0 && (
                            <Box className="d-flex flex-row" style={{ width: "100%", padding: "8px 32px 16px 32px" }}>
                                <SessionCounts title="Interview statuses overview" sessionCounts={sessionStatusCounts} />
                            </Box>
                        )}
                        {sessionDecisionCounts.data && sessionDecisionCounts.data.reduce((a, b) => a + b, 0) > 0 && (
                            <Box className="d-flex flex-row" style={{ width: "100%", padding: "8px 32px 16px 32px" }}>
                                <SessionCounts title="Interview decisions overview" sessionCounts={sessionDecisionCounts} />
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <Box className="d-flex flex-row" style={{ width: "100%", padding: "8px 32px 16px 32px" }}>
                            <Skeleton variant="rectangular" height={170} sx={{ width: '100%', borderRadius: '16px' }} />
                        </Box>
                        <Box className="d-flex flex-row" style={{ width: "100%", padding: "8px 32px 16px 32px" }}>
                            <Skeleton variant="rectangular" height={170} sx={{ width: '100%', borderRadius: '16px' }} />
                        </Box>
                    </>
                )}

            </Box>
        )}

        </DndContext>
    );
}

export default Analytics;

