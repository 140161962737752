import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    DndContext,
    useSensors,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    closestCenter
  } from "@dnd-kit/core";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, CircularProgress, IconButton, MenuItem } from '@mui/material';
import ChipTag from "../common/ChipTag";
import { SessionStatus, SessionDecision } from "../../util/constants";
import api from "../../api/api";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField, InputAdornment } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { normalizeScore, getColorFromScore, DEFAULT_SCALE } from '../../util/interviews'
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Sort from '@mui/icons-material/Sort';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import homesplash from '../../assets/homesplash.jpg';
import Splash from '../common/Splash';

const PAGE_SIZE = 50;

const Home = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const teamId = localStorage.getItem("teamId");
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );
    const [currentTab, setCurrentTab] = useState("interviews");
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [initSessionsCount, setInitSessionsCount] = useState(null);
    const [totalSessions, setTotalSessions] = useState(0);
    const [isFetchingInitial, setIsFetchingInitial] = useState(true);
    const [isFetchingNew, setIsFetchingNew] = useState(false);
    const [pagination, setPagination] = useState({}); // fields include total, page, totalPages, limit, startIndex, endIndex
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('last_status_timestamp');
    const [sortOrder, setSortOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    function clipText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    useEffect(() => {
        const fetchInitSessionsCount = async () => {
            try {
                const response = await api.countSessions({ teamId });
                setInitSessionsCount(response.data.count);
            } catch (error) {
                console.error('Error fetching initial sessions count:', error);
                setInitSessionsCount(0);
            }
        };

        fetchInitSessionsCount();
    }, [teamId]);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                setIsFetchingNew(true);
                const response = await api.searchSessions({ teamId, page, limit: PAGE_SIZE, sortBy, sortOrder, name, startDate, endDate });
                const { sessions, pagination } = response.data;
                setTotalSessions(pagination.total || 0);
                setPagination(pagination || {});
                setFilteredSessions(sessions || []);
                setIsFetchingNew(false);
                setIsFetchingInitial(false);
            } catch (error) {
                console.error('Error fetching sessions:', error);
                setPagination({});
                setFilteredSessions([]);
                setIsFetchingInitial(false);
                setIsFetchingNew(false);
            }
        };

        fetchSessions();
    }, [page, sortBy, sortOrder, name, startDate, endDate]);

    if (isLoading) {
        return (
            <CircularProgress
            sx={{ color: "var(--color-primary)", margin: "auto" }}
            />
        );
    }

    {/* Default screen if team has no interviews yet */}
    if (initSessionsCount === 0) {
        return (
            <Splash
                img={homesplash}
                title="No interviews yet."
                paragraph="Kickstart your hiring process! Create your first interview and start screening candidates today."
            />
        )
    }

    return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
        >

        {/* Loading Skeleton */}
        {isFetchingInitial && (
            <Box className="d-flex flex-col" style={{ padding: "24px 32px 16px 32px", width: "100%", marginLeft: "auto" }}>
                <Box className="d-flex flex-col">
                    <Skeleton variant="text" width={50} height={30} />
                    <Skeleton variant="text" width={100} height={18} />
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: '24px', gap: '12px' }}>
                    <Skeleton variant="text" width={90} height={40} />
                </Box>
                <Skeleton variant="rectangular" width="100%" height={500} style={{ borderRadius: '12px', marginTop: '24px' }}/>
            </Box>
        )}

        {initSessionsCount && !isFetchingInitial && (
            <Box
                    style={{
                    background: "#FAFAFA",
                    width: "100%",
                    }}
                >
            <Box
                style={{
                background: "#FAFAFA",
                width: "100%",
                margin: "auto",
                }}
            >
                {/* Home Header  */}
                <Box
                style={{
                    width: "100%",
                    background: "white",
                    padding: "24px 32px 16px 32px",
                }}
                >
                <div className="d-flex flex-row" style={{ width: "100%" }}>
                    <Box className="d-flex" style={{ background: "white" }}>
                        <Box className="d-flex flex-col">
                        <Box className="d-flex flex-row">
                            <h3
                            className="my-auto"
                            style={{ marginRight: "8px" }}
                            >
                            Home
                            </h3>
                        </Box>
                        <h5
                            className="color-gray my-auto"
                            style={{ fontWeight: 500, marginTop: "8px" }}
                        >
                            {totalSessions} candidates 
                        </h5>
                        </Box>
                    </Box>

                    {/* Add interview button */}
                    <div
                        className="d-flex flex-row"
                        style={{ marginLeft: "auto" }}
                    >
                        <button 
                            className="btn-main d-flex"
                        onClick={() => {
                            navigate('/create-interview')
                        }}
                        >
                            <Add
                            fontSize="14px"
                            className="my-auto"
                            sx={{ marginRight: "2px" }}
                            ></Add>{" "}
                            Add Interview
                        </button>
                    </div>
                    </div>
                </Box>
            </Box>

                {/* Tabs */}
            <Box
                sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "white",
                }}
            >
                <Tabs
                value={currentTab}
                onChange={(_, value) => setCurrentTab(value)}
                disableRipple
                style={{ padding: "0px 32px", background: "white" }}
                sx={{
                    "& .MuiTabs-indicator": {
                    backgroundColor: "#0D0E14",
                    },
                }}
                >
                    <Tab
                        label="Interviews"
                        value="interviews"
                        disableRipple
                        style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "interviews" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "interviews" ? "black" : "darkgray",
                        zIndex: currentTab === "interviews" ? 10 : 0,
                        }}
                    />
                    {/* <Tab
                        label="Profiles"
                        value="profiles"
                        disableRipple
                        style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: currentTab === "profiles" ? 600 : 500,
                        fontFamily: "PPNeueMontreal",
                        color: currentTab === "profiles" ? "black" : "darkgray",
                        zIndex: currentTab === "profiles" ? 10 : 0,
                        }}
                    /> */}
                </Tabs>
            </Box>

            <Box
            style={{
                margin: "24px 32px",
                background: "white",
                border: "1px solid lightgray",
                borderRadius: "12px",
            }}
            >

            <div
            className="d-flex flex-row"
            style={{
                padding: "12px 16px",
                borderBottom: "1px solid lightgray",
                marginBottom: 0,
            }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                    <TextField
                        select
                        size="small"
                        defaultValue="newest"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    sx: {
                                        borderRadius: '8px',
                                        marginTop: '4px',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                        '& .MuiMenuItem-root': {
                                            fontSize: '14px',
                                            fontFamily: 'PPNeueMontreal, sans-serif',
                                            fontWeight: 'bold',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            paddingLeft: '44px',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary-bg-hover)'
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: 'var(--color-primary-bg)',
                                                '&:hover': {
                                                    backgroundColor: 'var(--color-primary-bg-hover)'
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Sort sx={{ color: 'black', fontSize: 18 }} />
                                </InputAdornment>
                            ),
                            IconComponent: () => null
                        }}
                        sx={{
                            width: '225px',
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                fontFamily: 'PPNeueMontreal, sans-serif',
                                fontWeight: 'bold',
                                paddingLeft: '4px',
                                paddingRight: '4px !important'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                transition: 'box-shadow 0.2s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'var(--color-midgray)',
                                    borderWidth: '1px',
                                },
                            },
                            '& .MuiSelect-select': {
                                paddingRight: '4px !important'
                            }
                        }}
                        onChange={async (e) => {
                            const value = e.target.value;
                            let newSortBy, newSortOrder;
                            
                            switch(value) {
                                case 'newest':
                                    newSortBy = 'last_status_timestamp';
                                    newSortOrder = 'desc';
                                    break;
                                case 'oldest':
                                    newSortBy = 'last_status_timestamp';
                                    newSortOrder = 'asc';
                                    break;
                                case 'highest':
                                    newSortBy = 'overall_score';
                                    newSortOrder = 'desc';
                                    break;
                                case 'lowest':
                                    newSortBy = 'overall_score';
                                    newSortOrder = 'asc';
                                    break;
                                default:
                                    return;
                            }

                            setSortBy(newSortBy);
                            setSortOrder(newSortOrder);
                            setPage(1);
                        }}
                    >
                        <MenuItem value="newest">Update: Newest to oldest</MenuItem>
                        <MenuItem value="oldest">Update: Oldest to newest</MenuItem>
                        <MenuItem value="highest">Score: Highest to lowest</MenuItem>
                        <MenuItem value="lowest">Score: Lowest to highest</MenuItem>
                    </TextField>
                </Box>

                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            clearable
                            value={startDate ? dayjs(startDate) : null}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    placeholder: "Start date",
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setStartDate('');
                                        setPage(1);
                                    }
                                },
                                inputAdornment: {
                                    position: 'start',
                                }
                            }}
                            slots={{
                                openPickerIcon: () => <CalendarTodayIcon sx={{ fontSize: 18, color: 'black', marginLeft: '4px' }} />,
                                clearIcon: () => <CloseIcon sx={{ fontSize: 18, color: 'black', marginRight: '4px' }} />,
                            }}
                            sx={{
                                width: '160px',
                                '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                    fontFamily: 'PPNeueMontreal, sans-serif',
                                    fontWeight: 'bold',
                                    borderRadius: '8px',
                                    paddingTop: '1.5px',
                                    paddingBottom: '1.5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    transition: 'box-shadow 0.2s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                        borderWidth: '1px',
                                    },
                                },
                                '& .MuiIconButton-root': {
                                    padding: '4px',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }
                            }}
                            onChange={(date) => {
                                setPage(1);
                                setStartDate(date ? date.format('YYYY-MM-DD') : '');
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiPaper-root': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiPickersDay-root': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'var(--color-primary)',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary)',
                                            }
                                        }
                                    },
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                    },
                                    '& .MuiPickersCalendarHeader-label': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }
                                }
                            }}
                        />

                        <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    placeholder: "End date",
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setEndDate('');
                                        setPage(1);
                                    }
                                },
                                inputAdornment: {
                                    position: 'start'
                                }
                            }}
                            slots={{
                                openPickerIcon: () => <CalendarTodayIcon sx={{ fontSize: 18, color: 'black', marginLeft: '4px' }} />,
                                clearIcon: () => <CloseIcon sx={{ fontSize: 18, color: 'black', marginRight: '4px' }} />,
                            }}
                            sx={{
                                width: '160px',
                                '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                    fontFamily: 'PPNeueMontreal, sans-serif',
                                    fontWeight: 'bold',
                                    borderRadius: '8px',
                                    paddingTop: '1.5px',
                                    paddingBottom: '1.5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    transition: 'box-shadow 0.2s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'var(--color-midgray)',
                                        borderWidth: '1px',
                                    },
                                },
                                '& .MuiIconButton-root': {
                                    padding: '4px',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }
                            }}
                            onChange={(date) => {
                                setPage(1);
                                setEndDate(date ? date.format('YYYY-MM-DD') : '');
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiPaper-root': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiPickersDay-root': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                        '&.Mui-selected': {
                                            backgroundColor: 'var(--color-primary)',
                                            '&:hover': {
                                                backgroundColor: 'var(--color-primary)',
                                            }
                                        }
                                    },
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                    },
                                    '& .MuiPickersCalendarHeader-label': {
                                        fontFamily: 'PPNeueMontreal, sans-serif',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>

                {/* Search Bar */}
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
                    {/* Search Chip */}
                    {name && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                            <ChipTag
                                text={name}
                                onDelete={() => {
                                    setName('');
                                    setPage(1);
                                }}
                                deleteIcon={<CloseIcon style={{ fontSize: '16px' }}/>}
                                sx={{
                                    backgroundColor: '#E0E0E0',
                                    color: '#424242',
                                    height: '28px',
                                    '& .MuiChip-deleteIcon': {
                                        color: '#757575',
                                        '&:hover': {
                                            color: '#424242'
                                        },
                                    },
                                    width: 'max-content'
                                }}
                            />
                        </Box>
                    )}
                    <TextField
                    value={search}
                    variant="outlined" 
                    size="small"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                onClick={() => {
                                    setName(search);
                                    setSearch('');
                                    setPage(1);
                                }}
                                edge="start"
                                size="small"
                            >
                                <SearchIcon style={{ color: "black", fontSize: "18px", fontWeight: "bold" }} />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setName(search);
                            setSearch('');
                            setPage(1);
                        }
                    }}
                    placeholder="Search Candidates"
                    sx={{ 
                        width: "200px",
                        '& .MuiInputBase-input': {
                            fontSize: '14px',
                            fontFamily: 'PPNeueMontreal, sans-serif',
                            fontWeight: 'bold',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            transition: 'box-shadow 0.2s ease-in-out',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--color-midgray)',
                                borderWidth: '1px',
                            },
                        }
                    }}
                    onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        setSearch(value);
                    }}
                    />
                </div>
            </div>

            <>
            <TableContainer style={{ overflowX: "auto" }}>
                <Table>
                <TableHead>
                    <TableRow
                    sx={{
                        "& .MuiTableCell-root": {
                        lineHeight: "20px",
                        backgroundColor: "#FAFAFA",
                        color: "var(--color-heading)",
                        padding: "12px 16px",
                        },
                    }}
                    >
                    <TableCell sx={{ width: '20%' }}>Candidate Name</TableCell>
                    <TableCell sx={{ width: '12.5%' }}>Status</TableCell>
                    <TableCell sx={{ width: '12.5%' }}>Updated</TableCell>
                    <TableCell sx={{ width: '12.5%' }}>Decision</TableCell>
                    <TableCell sx={{ width: '12.5%' }}>Score</TableCell>
                    <TableCell sx={{ width: '30%' }}>Interview Config</TableCell>
                    </TableRow>
                </TableHead>
                {isFetchingNew ? (
                    <TableBody>
                        {[...Array(5)].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                                <TableCell><Skeleton variant="text" width="90%" height="30px" /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                <TableBody>
                    {filteredSessions.map((session, idx) => {
                    return (
                        <TableRow
                        className={`clickableRow`}
                        key={idx}
                        onClick={() => {
                            navigate(`/interviews/${session.interview_config_id}/sessions/${session._id}`);
                        }}
                        style={{ cursor: "pointer", height: "64px" }}
                        sx={{
                            cursor: 'pointer',
                            '& .MuiTableCell-root': {
                                borderTop: '1px solid transparent',
                                borderBottom: '1px solid #e0e0e0',
                            },
                            '&:hover .MuiTableCell-root': {
                                borderTop: '1px solid var(--color-primary)',
                                borderBottom: '1px solid var(--color-primary)',
                            }
                        }}
                        >
                        <TableCell>
                            {session.candidate_metadata && (
                            <>
                                {clipText(session.candidate_metadata.first_name + " " + session.candidate_metadata.last_name, 25)}
                            </>
                            )}
                            {!session.candidate_metadata && (
                            <>N/A</>
                            )}
                        </TableCell>
                        <TableCell>
                            {session.voicemail_detected ? (
                            <ChipTag
                                variant="default"
                                text="Voicemail"
                            />
                            ) : (
                            <>
                                {session.last_status === 
                                SessionStatus.NOT_STARTED && (
                                <ChipTag
                                    variant="not-started"
                                    text="Not Started"
                                />
                                )}
                                {session.last_status ===
                                SessionStatus.IN_PROGRESS && (
                                <ChipTag
                                    variant="in-progress"
                                    text="In Progress"
                                />
                                )}
                                {session.last_status ===
                                SessionStatus.COMPLETED && (
                                <ChipTag
                                    variant="completed"
                                    text="Completed"
                                />
                                )}
                                {session.last_status ===
                                SessionStatus.INCOMPLETE && (
                                <ChipTag
                                    variant="in-progress"
                                    text="Incomplete"
                                />
                                )}
                            </>
                            )}
                        </TableCell>
                        <TableCell>
                            {session.last_status_timestamp ? (() => {
                                const now = new Date();
                                const timestamp = new Date(session.last_status_timestamp);
                                const diffMs = now - timestamp;
                                const diffMins = Math.floor(diffMs / (1000 * 60));
                                const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
                                const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

                                if (diffMins < 60) {
                                    return `${diffMins} min ago`;
                                } else if (diffHrs < 24) {
                                    return `${diffHrs} hr ago`;
                                } else if (diffDays <= 7) {
                                    return `${diffDays} days ago`;
                                } else {
                                    return timestamp.toLocaleDateString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric'
                                    });
                                }
                            })() : 'N/A'}
                        </TableCell>
                        <TableCell>
                            {session.overall_decision === SessionDecision.PASS ? (
                                <ChipTag variant="completed" text="Accept" />
                            ) : session.overall_decision === SessionDecision.FAIL ? (
                                <ChipTag variant="reject" text="Reject" />
                            ) : session.overall_decision === SessionDecision.UNDECIDED ? (
                                <ChipTag variant="pending" text="Pending" />
                            ) : (
                                <ChipTag variant="pending" text="Pending" />
                            )}
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: getColorFromScore(session.overall_score, session.overall_scale || DEFAULT_SCALE),
                                    color: 'white',
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '4px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}
                            >
                                {session.overall_score === -1 ? '-' : (session.overall_score ? session.overall_score.toFixed(1) : '-')}
                            </Box>
                        </TableCell>
                        <TableCell>
                            <a href={`/interviews/${session.interview_config_id}`} style={{ textDecoration: 'underline', color: 'black' }}>
                                {clipText(session.interview_config_title, 40)}
                            </a>
                        </TableCell>
                        </TableRow>
                    );
                    })}
                </TableBody>
                )}
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <button
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        fontSize: '14px',
                        fontFamily: 'PPNeueMontreal, sans-serif',
                        fontWeight: 'bold',
                        marginRight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <ArrowBackIcon sx={{ fontSize: 14, marginRight: 1 }} /> Previous
                </button>
                
                <span style={{ 
                    fontSize: '14px',
                    fontFamily: 'PPNeueMontreal, sans-serif',
                    fontWeight: 'bold',
                }}>
                    {pagination.startIndex}-{pagination.endIndex} of {pagination.total}
                </span>

                <button
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        fontSize: '14px',
                        fontFamily: 'PPNeueMontreal, sans-serif',
                        fontWeight: 'bold',
                        marginLeft: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setPage(page + 1)}
                    disabled={page >= pagination.totalPages}
                >
                    Next <ArrowBackIcon sx={{ fontSize: 14, marginLeft: 1, transform: 'rotate(180deg)' }} />
                </button>
            </Box>
            </>
                </Box>
            </Box>
        )}

        </DndContext>
    );
}

export default Home;

