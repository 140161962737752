import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavItem({ path, icon, text, clicked, endDecoration, endDecorationClicked }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`d-flex flex-row nav-item-wrapper clickable ${location.pathname.includes(path) ? 'selected' : ''}`}
        style={{
          backgroundColor: location.pathname.includes(path) ? 'var(--color-hover-dark)' : 'var(--color-dark-mode)',
        }}
        onClick={() => {
          if (clicked) {
            clicked();
          } else {
            navigate(path);
          }
        }}
        >
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {icon}
          <h4 className="font-inter">{text}</h4>
        </div>
        {endDecoration}
      </div>
    </>
  );
}

export default NavItem;
