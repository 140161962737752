import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import {
  Box,
  Avatar,
  Tabs,
  Tab,
  CircularProgress,
  LinearProgress,
  Alert,
  AlertTitle,
  Checkbox,
  Snackbar
} from '@mui/material'
import {
  ErrorOutline,
  Share,
  VideocamOff,
} from '@mui/icons-material'
import IconLinkedIn from '../IconLinkedIn.js';
import ChipTag from '../components/common/ChipTag'
import { Scrollbars } from 'react-custom-scrollbars';

import WayfasterLogo from "../wayfaster-logo.svg";

import api from '../api/api'
import { normalizeScore, getColorFromScore, getColorBgFromScore } from '../util/interviews'

const SessionStatus = {
  NOT_STARTED: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  IN_PROGRESS: 4
}

const SessionStatusString = {
  [SessionStatus.NOT_STARTED]: 'Not Started',
  [SessionStatus.INCOMPLETE]: 'Incomplete',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.IN_PROGRESS]: 'In Progress'
}

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'
// var vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'

var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
}

const INTERVIEW = 0
const BACKGROUND = 1

const INFO = 0
const EDUCATION = 1
const SKILLS = 2

const SUMMARY = 0
const TRANSCRIPT = 1
const SCORECARD = 2
const QUESTIONS_ASKED = 3
const OUTPUTS = 4

const thumbVLight = ({ style, ...props }) => (
  <div style={{ ...style, backgroundColor: '#d6d6d6', borderRadius: '10px', cursor: 'pointer' }} {...props} />
);
function CandidateDetailPublic() {
  const BASE_SCORE = 5;
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const location = useLocation();
  const { candidate_id } = useParams();
  const [interview, setInterview] = useState({});
  const [session, setSession] = useState({});
  const [videoRecordingLink, setVideoRecordingLink] = useState('')
  const [audioRecordingLink, setAudioRecordingLink] = useState('');
  const [candidate, setCandidate] = useState({});

  const [currentTab, setCurrentTab] = useState(0);
  const [currentSessionTab, setCurrentSessionTab] = useState(0);
  const [currentSideTab, setCurrentSideTab] = useState(0);

  // misc
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentScorecard, setCurrentScorecard] = useState([])

  const actionsRef = React.useRef(null);
  const shareRef = React.useRef(null);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  }

  const TextSection = ({title, subheadings, description, thumbnail }) => {
    return (
      <>
        <div className='d-flex flex-row' style={{ width: '100%', marginBottom: '24px' }}>
          {/* Thumbnail / default Divider */}
          {thumbnail ? 
            <div className="d-flex" style={{ width: '32px', height: '32px', backgroundColor: 'var(--color-divider)', borderRadius: '8px', marginRight: '16px',
              backgroundImage: thumbnail.length == 1 ? '' : `url(${thumbnail})`, backgroundSize: 'cover', border: '1px solid lightgray' }}> 
              <h3 className="m-auto font-circular" style={{ fontFamily: 'Inter, sans-serif' }}>{ thumbnail.length == 1 ? thumbnail : '' }</h3>
            </div>
          :
          <div className='d-flex flex-col' style={{ width: '20px' }}>
            <span style={{ width: '4px', height: '18px', backgroundColor: 'var(--color-divider)' }}>
            </span>
          </div>}
          {/* Heading and Text */}
          <div className='d-flex flex-col' style={{ flex: 1 }}>
            <h5 className='font-circular lh-20 section-heading color-heading'>{title}</h5>
            {subheadings.map((subheading, idx) => {
              return <p className={`font-circular lh-20 section-heading color-subheading${idx == 0 ? '' : '-light'}`} 
                style={{ lineHeight: '20px', fontSize: '14px', marginBottom: '4px' }}>
                {subheading}
              </p>
            })}
            <p className='font-circular m-0 lh-20 color-subheading' style={{ lineHeight: '20px', fontSize: '14px' }}>{description}</p>
          </div>

        </div>
      </>
    )
  }

  useEffect(() => {
    api.getCandidate({ candidateId: candidate_id }).then((response) => {
      setCandidate(response.data)
      const candidate_session = response.data.session

      console.log('candidate_session', candidate_session)
      if (candidate_session) {
        setAudioRecordingLink(candidate_session.recording_url)
        setCurrentMessages(candidate_session.messages)
        setCurrentScorecard(candidate_session.scorecard)
        setSession(candidate_session)

        

      } else {
        setSession(null);
      }
    })
    
    axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session._id })
    .then((response) => {
      setVideoRecordingLink(response.data.url)
      console.log(response.data.url)
    })
    .catch((error) => {
      console.error('Error generating video recording link:', error);
    });      
    return
  }, [candidate_id, session._id]);

  const navigate = useNavigate()

  const handleSessionTabChange = (event, newValue) => {
    setCurrentSessionTab(newValue);
  }

  const handleSideTabChange = (event, newValue) => {
    setCurrentSideTab(newValue);
  }

  const sessionScore = useMemo(() => {
    if (!currentScorecard || currentScorecard.length === 0) return -1;

    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }

    let weightedSum = 0;
    let totalWeight = 0;

    currentScorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    return weightedAverage.toFixed(1);
  }, [currentScorecard])

  const passSession = (session) => {
    if (!session.scorecard || session.scorecard.length === 0 || !session.metadata.interview_config || !currentScorecard) return -1;
    // Check if weights add up to 100, if not, set them to equal weights
    const totalWeightCheck = currentScorecard.reduce((sum, item) => sum + (item.weight || 0), 0);
    if (Math.abs(totalWeightCheck - 100) > 0.001) {
      const equalWeight = 25;
      currentScorecard.forEach(item => item.weight = equalWeight);
    }    
    
    let weightedSum = 0;
    let totalWeight = 0;

    session.scorecard.forEach((scorecardItem) => {
      const configItem = currentScorecard.find(item => item.signal === scorecardItem.signal);
      if (configItem) {
        const weight = (configItem.weight / 100) || 0;
        console.log('weight', weight)
        weightedSum += scorecardItem.score * weight;
        totalWeight += weight;
      }
    });

    if (totalWeight === 0) return -1;

    const weightedAverage = weightedSum / totalWeight;
    const avgFixed = weightedAverage.toFixed(1);
    return avgFixed >= 2.5;
  }

  const renderRubricItemCount = (scorecard) => {
    let itemCount = 0;
    if(!scorecard || scorecard === undefined) {
      return 'N / A'
    }

    scorecard.map((rubricItem) => {
      if(rubricItem.score >= 3) {
        itemCount += 1
      }
      return 0
    })

    return `${itemCount} / ${scorecard.length} (${((itemCount / scorecard.length) * 100).toFixed(2)}%)`
  }

	const renderOutputValue = (value) => {
		if(!Array.isArray(value)){
			return value;
		}

		if (value.length === 0) {
			return '[]';
		}


		const formattedItems = value.map(item => {
			if (typeof item === 'string') {
				return `'${item}'`;
			}
			return String(item);
		});

		const output = '[\n  ' + formattedItems.join(',\n  ') + '\n]';
		return output;
	}

  const renderStructuredOutputs = (structuredOutputs) => {
    if(!structuredOutputs) {
      return <>
              <div className="d-flex flex-col section">
                <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                  No outputs found yet.
                </p>
              </div>
            </>
    }

    return structuredOutputs.map((output) => {
      return (
        <div className="d-flex flex-col section" style={{ padding: '12px', borderRadius: '12px', border: '1px solid lightgray' }}>
          {Object.entries(output).map(([key, value]) => (
            <>
              <div className="section-heading">
                <ChipTag variant="default" text={key} style={{ width: 'max-content' }} />
              </div>
              <p className="font-circular section-body" style={{ fontSize: '14px' }}>
                {renderOutputValue(value)}
              </p>
            </>
            )
          )}
        </div>
      )
    });
  }

  // Render
  return (
    <Box sx={{display: 'flex'}}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
      {/* Session */}
      <Box style={{ background: 'white', borderRadius: 6, width: '100%' }}>
        
        {!session == null &&
            <>
              <p className="font-circular">None</p>
            </>
          }
        {(!session || Object.keys(session).length === 0) &&
          <>
            <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
          </>
        }
        {(session && Object.keys(session).length > 0) &&
          <Box className="d-flex flex-col" style={{ height: '100%'}}>
            {/* Wayfaster Header */}
            <div className="d-flex flex-row mx-auto" style={{ padding: '8px 32px 0px' }}>
              <img className="mx-auto clickable-grow" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} onClick={() => {
                window.open('https://wayfaster.com', '_blank');
              }}/>
            </div>
            {/* Session Header */}
            <Box style={{ width: '100%', padding: '16px 24px', borderBottom: '1px solid lightgray' }}>
              <Box className="d-flex flex-row" style={{ width: '100%' }}>
                <div className="d-flex flex-row" style={{ width: '100%' }}>
                  <Avatar
                    className="font-circular my-auto"
                    src={candidate.user ? candidate.user.picture : ''}
                    sx={{
                      color: "black",
                      width: 48,
                      height: 48,
                      borderRadius: "50%",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      marginRight: "16px",
                    }}
                  >
                    {candidate ? candidate.first_name[0] + candidate.last_name[0] : ''}
                  </Avatar>    
                  <div className="d-flex flex-col" style={{ marginRight: 'auto' }}>
                    <div className="d-flex flex-row" style={{ marginRight: 'auto' }}>
                      {candidate &&
                        <>
                          <h3 className="font-circular my-auto" style={{ fontSize: '20px' }}>{candidate.first_name} {candidate.last_name}</h3>
                        </>
                      }

                      {session.voicemail_detected ?
                        <ChipTag variant="default" text="Voicemail" style={{ marginLeft: '8px' }} />
                      :
                      <>
                        {session.status === SessionStatus.INCOMPLETE &&
                          <ChipTag variant="in-progress" text="Incomplete" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.IN_PROGRESS &&
                          <ChipTag variant="in-progress" text="In Progress" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.NOT_STARTED &&
                          <ChipTag variant="not-started" text="Not Started" style={{ marginLeft: '8px' }} />
                        }

                        {session.status === SessionStatus.COMPLETED &&
                          <ChipTag variant="completed" text="Completed" style={{ marginLeft: '8px' }} />
                        }


                        {session.status === SessionStatus.COMPLETED ?
                            (!session.scorecard || session.scorecard.length === 0 ?
                                <ChipTag variant="default" text="N/A" style={{ marginLeft: '8px' }} /> :
                                ((session.customerOverrideCandidateAction === 'accept' || passSession(session)) ?
                                    <ChipTag variant="accept" text="Accept" style={{ marginLeft: '8px' }} /> :
                                    <ChipTag variant="reject" text="Reject" style={{ marginLeft: '8px' }} />)
                            ) :
                            <ChipTag variant="pending" text="Pending" style={{ marginLeft: '8px' }}/>
                        }

                        {session.potentialCheating &&
                          <ChipTag variant="danger" text="Suspicious Activity" style={{ marginLeft: '8px', backgroundColor: '#FF4D4D', color: 'white' }} />
                        }
                      </>
                      }
                    </div>

                    <div className='d-flex flex-row'>
                      <p className='font-circular color-gray' style={{ margin: 0, fontSize: '14px', marginTop: '4px' }}>
                        {candidate.best_suited_roles && candidate.best_suited_roles.length > 0 ? candidate.best_suited_roles[0] : session.metadata.interview_config.jobTitle}
                      </p>
                    </div>
                    {candidate.linkedin && candidate.linkedin.length > 0 && <div className='d-flex flex-row' style={{ marginTop: '4px' }}>
                        <IconLinkedIn className="color-gray clickable" style={{ marginRight: '12px' }} onClick={(e) => {
                          e.preventDefault();
                          window.open(candidate.linkedin, '_blank');
                        }}></IconLinkedIn>
                    </div>}

                    <h5 className="font-circular color-gray" style={{ margin: '4px 0px', fontWeight: 500, fontSize: '14px' }}>
                      {session && session.created_at.$date ? (
                        new Date(session.created_at.$date).toLocaleDateString()
                      ) : (
                        new Date(session.created_at * 1000).toLocaleDateString()
                      )}
                    </h5>
                  </div>

                  <div className="d-flex flex-row my-auto" style={{ marginLeft: 'auto' }}>
                    <button className="btn-main d-flex gray outlined my-auto"
                      ref={shareRef}
                      style={{ margin: 'auto 16px' }}
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href).then((val) => {
                          setSnackbarMsg('Public share link copied to clipboard successfully')
                          setOpenSnackbar(true)
                        })
                        .catch((err) => {console.error('could not copy')})
                      }}>
                        <Share fontSize="14px" className="color-heading m-auto" />
                      </button>
                  </div>
                </div>
              </Box>
            </Box>

            {/* Session Details */}
            <Box style={{ position: 'relative', flex: 1 }}>
              {session.status === SessionStatus.NOT_STARTED && <div className="m-auto" style={{ background: 'white', borderRadius: '8px', position: 'absolute', zIndex: 1000, left: 0, right: 0, top: '124px', height: 'fit-content', width: '352px', padding: '24px', boxShadow: '0px 1px 14px 0px #00000029'}}>
                  <h3 className="font-circular section-heading">This interview isn't completed yet</h3>
                  <p className="font-circular section color-gray" style={{ marginTop: '0px', fontSize: '14px' }}>
                    Once the candidate has completed the interview, we will display the results here.
                  </p>
              </div>}
              <Box className={`detail-cols-wrapper ${session.status === SessionStatus.NOT_STARTED ? 'blurred non-interactive' : ''}`}>
                {/* Main Details */}
                <Box className="d-flex flex-col main-details">
                  {/* Video / Audio */}
                  <Box className="section">
                    {/* <p style={{ fontWeight: 600 }}>Video Recording</p> */}
                    <div className="section" style={{ borderRadius: '8px', overflow: 'hidden' }}>
                      {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                        <a className="loom-video" href={session.videoRecordingUrl}></a>
                      }
                      {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                        <>
                          {(videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                            <video
                              width="100%"
                              type="video/mp4"
                              controls
                              preload="auto"
                              style={{ borderRadius: '8px' }}
                            >
                              <source src={videoRecordingLink} type="video/mp4" />
                            </video>
                            :
                            <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9', borderRadius: '8px' }}>
                              <VideocamOff className="m-auto" style={{ color: '#ACACAC' }}/>
                            </div>
                          }
                        </>
                      }
                    </div>
                    {audioRecordingLink && <div className="d-flex flex-col">
                      <h4 className="font-circular section-heading" style={{ marginLeft: '8px' }}>Audio Recording</h4>
                      <audio controls
                        className="audio-player"
                        style={{ background: 'transparent', padding: '4px', width: '60%', marginLeft: '-8px' }}>
                          <source src={audioRecordingLink} type="audio/mpeg" />
                      </audio>
                    </div>}
                  </Box>
                </Box>

                {/* Side Bar (Background, Interview) */}
                <Box className="d-flex flex-col side-details" style={{ overflow: 'hidden'}}>
                  {/* Main Background / Interview Tabs */}
                  <Tabs value={currentTab} onChange={handleTabChange}
                    style={{ background: 'white', marginBottom: '24px', marginLeft: '-24px', marginRight: '-24px', marginTop: '-16px' }}
                    sx={{
                      '& .MuiTabs-scroller': {
                        borderBottom: '1px solid var(--color-divider)',
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'var(--color-main)',
                      },
                      '& .MuiTab-root': {
                        color: "darkgray", 
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        marginRight: '4px',
                        fontFamily: 'EuclidCircularA',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      '& .MuiTab-root.Mui-selected': {
                        color: 'var(--color-main)',
                        fontWeight: 600
                      },
                    }}>
                    <Tab label="Interview" onClick={() => setCurrentScorecard(session.scorecard)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />  
                    <Tab label="Background" onClick={() => setCurrentMessages(session.messages)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                  </Tabs>

                  {/* Background Tab */}
                  {currentTab === BACKGROUND && <>
                    {/* Sidebar Tabs */}
                    <Tabs value={currentSideTab} onChange={handleSideTabChange}
                      style={{ background: 'white', marginBottom: '16px' }}
                      sx={{
                        '& .MuiTabs-indicator': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiTab-root': {
                          color: "darkgray",
                          fontSize: '14px',
                          borderRadius: '8px',
                          padding: '4px 8px',
                          marginRight: '4px',
                          fontFamily: 'EuclidCircularA',
                          textTransform: 'none',
                          minHeight: '40px'
                        },
                        '& .MuiTab-root.Mui-selected': {
                          color: 'var(--color-main)',
                          backgroundColor: 'var(--color-lightgray)',
                          fontWeight: 600
                        },
                      }}>

                      <Tab label="General Info" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentSideTab === INFO ? 600 : 500, zIndex: currentSideTab === INFO ? 10 : 0 }} />
                      <Tab label="Education" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentSideTab === EDUCATION ? 600 : 500, zIndex: currentSideTab === EDUCATION ? 10 : 0 }} />
                      <Tab label="Skills" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentSideTab === SKILLS ? 600 : 500, zIndex: currentSideTab === SKILLS ? 10 : 0 }} />
                    </Tabs>
                    <Box style={{ flex: 1, overflowY: 'auto' }}>
                      {/* General Info */}
                      {currentSideTab === INFO && 
                      <>
                        {/* Experience */}
                        <h5 className="font-circular color-main m-0" style={{ marginBottom: '16px' }}>Last Experience</h5>
                        
                        <div className='d-flex flex-col' style={{ overflowY: 'auto' }}>
                          {/* Indented Text Section */}
                          {candidate.experiences && candidate.experiences.map((experience) => 
                            <TextSection title={experience.job_title} subheadings={[experience.employer, experience.dates_employed]} description={experience.description} thumbnail={experience.thumbnail}></TextSection>
                          )}
                          
                          {(!candidate.experiences || candidate.experiences.length === 0) && <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                            No experiences imported.
                          </p>}
                        </div>
                      </>}
                      
                      {/* Education */}
                      {currentSideTab === EDUCATION && 
                      <div className='d-flex flex-col'>
                        <h5 className="font-circular color-main m-0" style={{ marginBottom: '16px' }}>Education</h5>
                        
                        {/* Indented Text Section */}
                        {candidate.education && candidate.education.map((experience) => 
                          <TextSection title={experience.institution} subheadings={[experience.education_title, experience.dates_attended]} description={experience.description} thumbnail={experience.thumbnail}></TextSection>
                        )}
                          
                        {(!candidate.education || candidate.education.length === 0) && <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                          No education imported.
                        </p>}
                      </div>}
                      
                      
                      {/* Skills */}
                      {currentSideTab === SKILLS && 
                      <div className='d-flex flex-col'>
                        <h5 className="font-circular color-main m-0" style={{ marginBottom: '16px' }}>Skills</h5>
                        {candidate.skills && candidate.skills.map((skill) => 
                              <>
                                <div className="section-sm">
                                  <ChipTag variant="default" text={skill} style={{ width: 'max-content', marginBottom: '12px' }} />
                                </div>
                              </>
                            )}
                          {(!candidate.skills || candidate.skills.length === 0) && <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                            No skills imported.
                          </p>}
                      </div>}
                    </Box>
                  </>}

                  {/* Interview Tab */}
                  {currentTab === INTERVIEW && <>

                    <Tabs value={currentSessionTab} onChange={handleSessionTabChange}
                    style={{ background: 'white', marginBottom: '16px' }}
                    sx={{
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiTab-root': {
                        color: "darkgray",
                        fontSize: '14px',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        marginRight: '4px',
                        fontFamily: 'EuclidCircularA',
                        textTransform: 'none',
                        minHeight: '40px'
                      },
                      '& .MuiTab-root.Mui-selected': {
                        color: 'var(--color-main)',
                        backgroundColor: 'var(--color-lightgray)',
                        fontWeight: 600
                      },
                    }}>
                    <Tab label="Summary" disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    <Tab label="Transcript" onClick={() => setCurrentMessages(session.messages)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    <Tab label="Scorecard" onClick={() => setCurrentScorecard(session.scorecard)} disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />  
                    <Tab label="Questions Asked" disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    <Tab label="Outputs" disableRipple disabled={session.status === SessionStatus.NOT_STARTED } />
                    </Tabs>
                    <Scrollbars renderThumbVertical={thumbVLight} style={{ transition: 'all .3s ease', scrollbarColor: '#d6d6d6 white' }} sx={{ paddingRight: '16px' }}>

                      {/* Summary */}
                      { currentSessionTab === SUMMARY &&
                        <>
                          <div className="section">
                            {(session.summary === undefined && !session.internalOverrideReject) ? (
                              <p className="font-circular" style={{ fontSize: '14px', margin: 0 }}>N/A</p>
                            ) : (
                              typeof session.summary === 'string' ? (
                                <p className="font-circular" style={{ fontSize: '14px', margin: 0 }}>{session.summary}</p>
                              ) : (
                                <div>
                                  {Object.entries(session.summary).map(([key, points], sectionIndex) => {
                                    if (Array.isArray(points)) {
                                      return (
                                        <div key={sectionIndex} style={{ marginBottom: '16px' }}>
                                          <p className="font-circular" style={{ fontSize: '14px', fontWeight: 600, marginBottom: '8px', marginTop: '0' }}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                          </p>
                                          <ul style={{ fontSize: '14px', margin: 0, paddingLeft: '20px' }}>
                                            {points.map((point, i) => (
                                              <li key={i} className="font-circular" style={{ fontSize: '14px' }}>{point}</li>
                                            ))}
                                          </ul>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              )
                            )}
                          </div>

                          {(session.session_specific_questions && session.session_specific_questions.questions && session.session_specific_questions.questions.length > 0) &&
                            <div className="section" style={{ marginTop: '16px' }}>
                              <p style={{ fontSize: '14px', fontWeight: 600, marginBottom: '8px' }}>
                                Session-Specific Questions
                              </p>
                              <ol className="font-circular" style={{ marginLeft: '18px', padding: 0 }}>
                                {session.session_specific_questions.questions.map((session_specific_question, index) => {
                                  return (
                                    <li key={index} style={{ marginBottom: '10px', fontSize: '14px' }}>{session_specific_question.question}</li>
                                  )
                                })}
                              </ol>
                            </div>
                          }
                        </>
                      }

                      {/* Transcript */}
                      { currentSessionTab === TRANSCRIPT &&
                        <>
                          {(currentMessages && currentMessages.length > 0) ? currentMessages.map((msg) => {
                            return (
                              <div className="d-flex flex-col section" style={{ paddingRight: '16px' }}>
                                <h5 className="font-circular section-heading">{msg.message.role === 'assistant' ? <b>Wayfaster</b> : <b>{session.candidate_metadata ?
                                  <>
                                  <span style={{ lineHeight: '24px' }}>
                                    {session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name}
                                      <ChipTag className="m-auto" variant="default" text="Candidate" style={{ marginLeft: '12px', marginTop: '-2px' }} />
                                    </span>
                                  </>
                                  : 'Candidate' }</b>} &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className="font-circular color-gray" style={{ fontSize: '12px', fontWeight: 400 }}>{new Date((msg.createdAt - currentMessages[0].createdAt)*1000).toISOString().substr(11, 8)}</span>
                                </h5>
                                <p className="font-circular section-body" style={{ fontSize: '14px' }}>{msg.message.content}</p>
                              </div>
                            )
                          }) :
                            <div className="d-flex flex-col section">
                              <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                                No transcript found yet.
                              </p>
                            </div>
                          }
                        </>
                      }

                      {/* Scorecard */}
                      { currentSessionTab === SCORECARD &&
                        <>
                          {(currentScorecard && currentScorecard.length > 0) ?
                            <>
                              <div className="d-flex flex-row section" style={{ backgroundColor: getColorBgFromScore(sessionScore, DEFAULT_SCALE), width: 'auto', padding: '20px', borderRadius: "20px" }}>
                                <div className="d-flex" style={{ backgroundColor: getColorFromScore(sessionScore, DEFAULT_SCALE), height: '48px', width: '48px', borderRadius: "12px", marginRight: '16px' }}>
                                  <h2 className="font-circular m-auto" style={{ fontSize: '20px', color: 'white' }}>
                                    { sessionScore }
                                  </h2>
                                </div>
                                <h5 className="font-circular color-main-light my-auto">Overall Score</h5>
                              </div>

                              {currentScorecard.map((scorecardItem, index) => (
                                <div className="d-flex flex-col section" key={index}>
                                  <h5 className="font-circular section-heading">{scorecardItem.signal}</h5>
                                  <div className="d-flex flex-row section-heading">
                                    <h5 className="font-circular" style={{ margin: 0, marginRight: '16px', color: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE) }}>
                                      {scorecardItem.score}/{scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE}</h5>
                                    <LinearProgress className="my-auto" variant="determinate" value={normalizeScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE)}
                                      style={{ backgroundColor: "#DADADA", height: '4px', borderRadius: '4px', width: '128px' }}
                                      sx={{
                                        [`& .MuiLinearProgress-bar`]: {
                                          backgroundColor: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE),
                                        },
                                      }} />
                                  </div>
                                  <p className="font-circular section-body color-gray" style={{ fontWeight: 500, fontSize: '14px' }}>
                                    {scorecardItem.feedback}
                                  </p>
                                </div>
                              ))}
                            </>
                            :
                            <div className="d-flex flex-col section">
                              {session.internalOverrideReject && session.internalOverrideRejectReason ? (
                                <div className="automatic-rejection-alert">
                                  <Alert severity="error" icon={<ErrorOutline fontSize="inherit" />}>
                                    <AlertTitle>Automatic Rejection</AlertTitle>
                                    <strong>{session.internalOverrideRejectReason}</strong>
                                  </Alert>
                                </div>
                              ) : (
                                <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                                  No scorecard found yet.
                                </p>
                              )}
                            </div>

                          }
                        </>
                      }

                      {/* Questions Completed */}
                      { currentSessionTab === QUESTIONS_ASKED &&
                        <>
                          {(session.metadata && session.metadata.interview_config && session.metadata.interview_config.questions) ? (
                            <>
                              <div className="d-flex flex-row section" style={{ 
                                backgroundColor: getColorBgFromScore(
                                  Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                  100
                                ),
                                width: 'auto', 
                                padding: '20px', 
                                borderRadius: "20px" 
                              }}>
                                <div className="d-flex" style={{ 
                                  backgroundColor: getColorFromScore(
                                    Math.round((session.parrot_text_index + 1) / session.metadata.interview_config.questions.length * 100), 
                                    100
                                  ), 
                                  height: '48px', 
                                  width: 'auto', 
                                  minWidth: '48px', 
                                  borderRadius: "12px", 
                                  marginRight: '16px', 
                                  padding: '0 8px' 
                                }}>
                                  <h2 className="font-circular m-auto" style={{ fontSize: '16px', color: 'white', whiteSpace: 'nowrap' }}>
                                    {session.parrot_text_index + 1} / {session.metadata.interview_config.questions.length}
                                  </h2>
                                </div>
                                <h5 className="font-circular color-main-light my-auto">Questions Asked</h5>
                              </div>

                              {session.metadata.interview_config.questions.map((question, index) => (
                                <div className="d-flex flex-col section" key={index}>
                                  <div className="d-flex flex-row align-items-center">
                                    <Checkbox 
                                      checked={index <= session.parrot_text_index} 
                                      disabled 
                                      style={{padding: '0px', marginRight: '12px'}}
                                    />
                                    <h5 className={index <= session.parrot_text_index ? 'font-circular section-heading color-main' : 'font-circular section-heading color-gray'} style={{ margin: 0 }}>
                                      {question.question}
                                    </h5>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="d-flex flex-col section">
                              <p className="font-circular section-body color-gray" style={{ fontSize: '14px' }}>
                                No questions found.
                              </p>
                            </div>
                          )}
                        </>
                      }

                      {/* Outputs */}
                      { currentSessionTab == OUTPUTS &&
                        <>
                          {(session.metadata && session.metadata.interview_config && (session.structured_outputs || session.metadata.interview_config.found_structured_outputs)) ?
                            renderStructuredOutputs(session.structured_outputs || session.metadata.interview_config.found_structured_outputs)
                            :
                            renderStructuredOutputs(null)
                          }
                        </>
                      }
                    </Scrollbars>
                  </>}
                </Box>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default CandidateDetailPublic