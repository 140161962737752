import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Container, Drawer, Box } from "@mui/material";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import WayfasterLogo from "../wayfaster-logo-w.svg";

import CandidateProfile from "../components/candidate/CandidateProfile";
import { Sidebar } from "../components/nav/Sidebar";

import { candidate_data } from "../testdata/candidate_data";
import api from "../api/api"

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
// var proxyEndpoint = 'http://localhost:5555'
// var wsEndpoint = 'ws://localhost:5555'
// var vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'

var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}


function CandidateProfileFull({}) {
  const { id } = useParams();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [previousCandidateId, setPreviousCandidateId] = useState("");
  const [nextCandidateId, setNextCandidateId] = useState("");
  const [candidates, setCandidates] = useState(candidate_data);
  const [candidate, setCandidate] = useState({});
  const [session, setSession] = useState(null);
  const [videoRecordingLink, setVideoRecordingLink] = useState(null);

  
  useEffect(() => {
    api.getCandidate({ "candidateId": id })
      .then((response) => {
        console.log('candidate', response)
        setCandidate(response.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const navigate = useNavigate();

  const navigateAndPopulateCandidate = (id) => {};

  return (
    <div className="d-flex flex-row">
      {/* Nav Sidebar */}
      {/* {isAuthenticated && <Sidebar user={user} isAuthenticated={isAuthenticated} isLoading={isLoading}></Sidebar>} */}

      {/* Main Content */}
      <div className="d-flex flex-col" style={{ width: "100%" }}>
        {/* Candidate Header */}
        <Box
          style={{
            width: "100%",
            background: "white",
            padding: "24px 32px 16px 32px",
          }}
        >
          {/* Candidate Previous / Next */}
          <div className="d-flex flex-row" style={{ width: "100%" }}>
            <div
              className="d-flex flex-row clickable"
              style={{ marginRightt: "auto", height: "24px" }}
              onClick={() => {
                // go back;
              }}
            >
              <button className="btn-main small d-flex gray outlined"
              onClick={() => navigate('/candidates')}>
                <NavigateBefore
                  fontSize="14px"
                  className="color-heading m-auto"
                />
              </button>
              <h5
                className="active-link"
                style={{ margin: "auto 8px", lineHeight: "20px" }}
              >
                Back
              </h5>
            </div>
            <div
              className="d-flex flex-row"
              style={{ marginLeft: "auto", height: "24px" }}
            >
              <button
                className={`btn-main small d-flex gray outlined ${
                  previousCandidateId ? "" : "inactive"
                }`}
                disabled={!previousCandidateId}
                onClick={() => {
                  navigateAndPopulateCandidate(previousCandidateId);
                }}
              >
                <NavigateBefore
                  fontSize="14px"
                  className="color-heading m-auto"
                />
              </button>
              <h5
                onClick={() => {
                  console.log("test");
                }}
                className={[previousCandidateId ? "active-link" : "inactive"]}
                style={{ margin: "auto 8px", lineHeight: "20px" }}
              >
                Previous Candidate
              </h5>

              <h5
                className="inactive"
                style={{ margin: "auto 4px", lineHeight: "20px" }}
              >
                |
              </h5>

              <h5
                onClick={() => {
                  console.log("test");
                }}
                className={[nextCandidateId ? "active-link" : "inactive"]}
                style={{ margin: "auto 8px", lineHeight: "20px" }}
              >
                Next Candidate
              </h5>

              <button
                className={`btn-main small d-flex gray outlined ${
                  nextCandidateId ? "" : "inactive"
                }`}
                disabled={!nextCandidateId}
                onClick={() => {
                  navigateAndPopulateCandidate(nextCandidateId);
                }}
              >
                <NavigateNext
                  fontSize="14px"
                  className="color-heading m-auto"
                />
              </button>
            </div>
          </div>
        </Box>

        {/* Candidate Profile */}
        <CandidateProfile key={candidate._id} candidate={candidate} compact={false}></CandidateProfile>
      </div>
    </div>
  );
}

export default CandidateProfileFull;
